<template>
  <div>
    <!-- <b-img
      src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fjess-bailey-q10VITrVYUM-unsplash.jpg?alt=media&token=18c4b4c7-2421-45dc-a069-faaf88da3296"
      blank-color="#ccc"
      alt="placeholder"
      class="imgStyle_"
    /> -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fjess-bailey-q10VITrVYUM-unsplash2.jpg?alt=media&token=f88ac5f1-c854-41d6-a7d6-2e8982b9c532')`}"
      >
        <b-card-body class="text-center picSpace">
          <h2 class="title_">
            初めての方へ
          </h2>
          <b-card-text class="mb-2">
            はじめまして、skillismです。
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>
    <!-- <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fjess-bailey-q10VITrVYUM-unsplash2.jpg?alt=media&token=f88ac5f1-c854-41d6-a7d6-2e8982b9c532')`}"
      >
        <b-card-body class="text-center">
          <h2 class="title_">
            初めての方へ
          </h2>
        </b-card-body>
      </b-card>
    </section> -->
    <div class="explainSpace">
      <h2 class="title_">
        skillismとは
      </h2>
      <div class="subtitle_">
        学ぶ、働く、教える
      </div>
      <div class="subtitle_">
        そのステージを、シームレスに。
      </div>
      <div class="explain_ mt-3">
        学ぶタイミングも、働くきっかけも、<br>誰もが同じという訳ではありません。
      </div>
      <div class="explain_ mt-1">
        あなたのペースで、ひとつでも多くの可能性を見つけましょう。
      </div>
      <div class="explain_ mt-1">
        skillismは、興味のあることを学びたい、得意なことを教えたい、スキルを活かして働きたい、そんなあなたの出会いがここにあります。
      </div>
      <div class="explain_ mt-2">
        何歳でも、何度でも、世界中どこででも。
      </div>
      <b-row>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          size="lg"
          variant="outline-primary"
          class="ml-1 mt-3"
          @click="move('service')"
        >
          <div class="title_">
            サービスの紹介
          </div>
        </b-button>
      </b-row>
      <div class="imgSpace_">
        <b-img
          src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Flukas-blazek-GnvurwJsKaY-unsplash.jpg?alt=media&token=682fe251-2db2-4a0f-b0cb-5f4a6a1c6456"
          blank-color="#ccc"
          alt="placeholder"
          class="imgStyle_ mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BCardBody, BImg, BRow, BButton, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BImg,
    BRow,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
.imgStyle_ {
  width: 110%;
  height: 360px;
  object-fit: cover;
  margin: -5% -5% 0 -5%;
}
.explainSpace {
  width: 70%;
  margin: 5% auto;
}
.title_ {
  font-size: 3rem;
  font-weight: 600;
}
.subtitle_ {
  font-size: 3rem;
  font-weight: 600;
}
.explain_ {
  font-size: 1.8rem;
  font-weight: 600;
}
.imgSpace_ {
  padding: 0 4%;
}
.picSpace {
  padding: 7% 0;
}
</style>
