<template>
  <b-row class="match-height">
    <b-col lg="12">
      <IntroductionExplain />
      <IntroductionFeature />
      <!-- <IntroductionService /> -->
      <Footer />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import IntroductionExplain from '@/components/basic/about/IntroductionExplain.vue'
import IntroductionFeature from '@/components/basic/about/IntroductionFeature.vue'
// import IntroductionService from '@/components/basic/about/IntroductionService.vue'
import Footer from '@/components/basic/footer/Footer.vue'

export default {
  components: {
    BRow,
    BCol,
    IntroductionExplain,
    IntroductionFeature,
    // IntroductionService,
    Footer,
  },
}
</script>
