<template>
  <div>
    <div class="explainSpace">
      <h2 class="title_">
        <span class="underLine">skillismの5つ特徴</span>
      </h2>
      <div class="featureSpace">
        <div class="subtitle_">
          （１）ライフステージに縛られず、何度でも
        </div>
        <b-row>
          <b-col lg="6">
            <b-img
              src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fandras-kovacs-7WXzjOYlWZw-unsplash.jpg?alt=media&token=d352099f-e439-4df2-a6c7-ede87090206d"
              blank-color="#ccc"
              alt="placeholder"
              class="imgStyle_ mt-4"
            />
          </b-col>
          <b-col>
            <div class="explain_ mt-3">
              長寿化時代の到来により、人々は今までにないライフステージのかたちが求められてくるでしょう。
            </div>
            <div class="explain_ mt-1">
              これまでの「教育→就労→引退」と言った直線的なライフステージではなく、いつでも学び、そして働き、その知識を教え、さらに学ぶ、循環型のライフステージへ。
            </div>
            <div class="explain_ mt-1">
              誰もが多様性のある人生を自分の色で描く、そんな未来を支援するプラットフォームです。
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="featureSpace">
        <div class="subtitle_">
          （２）「学ぶ」と「働く」がリンクするので目標が見える化
        </div>
        <b-row>
          <b-col lg="6">
            <b-img
              src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fbethany-legg-75nbwHfDsnY-unsplash.jpg?alt=media&token=521c443d-b03f-431a-9dda-823bec8e36eb"
              blank-color="#ccc"
              alt="placeholder"
              class="imgStyle_ mt-4"
            />
          </b-col>
          <b-col>
            <div class="explain_ mt-3">
              この学びによってどんな自分に成れる？ 希望の仕事に必要なスキルは？
            </div>
            <div class="explain_ mt-1">
              学習と求人情報がリンクすることで、具体的な目標が立てやすく、学習意欲が高まります。学習履歴や講師による評価は、受講者のダッシュボードに蓄積。
            </div>
            <div class="explain_ mt-1">
              サイト上の履歴書にも反映され、努力や継続的な実績が見える仕組みです。
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="featureSpace">
        <div class="subtitle_">
          （３）ストレスフリーで最適化された学びへ
        </div>
        <b-row>
          <b-col lg="6">
            <b-img
              src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fdavid-emrich-InYrwwAukOI-unsplash.jpg?alt=media&token=2976df75-baa4-420c-a34d-c4122d771f88"
              blank-color="#ccc"
              alt="placeholder"
              class="imgStyle_ mt-4"
            />
          </b-col>
          <b-col>
            <div class="explain_ mt-3">
              ネット上にたくさんあるコンテンツの中から、学びに有益なコンテンツが探しやすいプラットフォームを提供。
            </div>
            <div class="explain_ mt-1">
              独自にセレクトした動画や学会セミナー、登録講師によるライブレッスンなど、自分に合ったレベルや学習スタイルのコンテンツを効率よく選択できます。
            </div>
            <div class="explain_ mt-1">
              企業内研修や人材育成ツールとして活用できます。
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="featureSpace">
        <div class="subtitle_">
          （４）スキルを自分のものにする、経験学習を重視
        </div>
        <b-row>
          <b-col lg="6">
            <b-img
              src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fscott-graham-5fNmWej4tAA-unsplash.jpg?alt=media&token=a2ea755f-f32a-43a1-a7c4-d1d93a178b49"
              blank-color="#ccc"
              alt="placeholder"
              class="imgStyle_ mt-4"
            />
          </b-col>
          <b-col>
            <div class="explain_ mt-3">
              知識学習（インプット）と経験学習（アウトプット）を繰り返すことで、スキルは実学として身についていきます。
            </div>
            <div class="explain_ mt-1">
              そのため、ライブレッスンでは経験学習（アウトプット）であるコミュニケーションによる学習を大切にしています。
            </div>
            <div class="explain_ mt-1">
              また現在のスキルレベルを知るためのコンテストやワークショップも積極的に推奨し、成長の実感を追求します。
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="featureSpace">
        <div class="subtitle_">
          （５）AIを羅針盤に、思いがけない「気づき」の冒険へ
        </div>
        <b-row>
          <b-col lg="6">
            <b-img
              src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fdenise-jans-9OBwt_VgPa0-unsplash.jpg?alt=media&token=02769869-f5d3-4d5a-9d62-1d2c75dac207"
              blank-color="#ccc"
              alt="placeholder"
              class="imgStyle_ mt-4"
            />
          </b-col>
          <b-col>
            <div class="explain_ mt-3">
              学習履歴からパーソナルな傾向を理解し、AIが多角的な視点でコンテンツをレコメンド。
            </div>
            <div class="explain_ mt-1">
              予想外な学びとの出会い、思いがけない職種の提案など、AIならではの「気づき」から、まだ知らなかった自分の可能性、新しい発見が広がります。
            </div>
            <div class="explain_ mt-1">
              <small>（2022年秋実装予定）</small>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-center">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          size="lg"
          variant="outline-primary"
          @click="move('service')"
        >
          <div class="title_">
            サービスの紹介
          </div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
.imgStyle_ {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.explainSpace {
  width: 70%;
  margin: 5% auto;
}
.title_ {
  font-size: 3rem;
  font-weight: 600;
}
.subtitle_ {
  font-size: 2rem;
  font-weight: 600;
  /* margin: 5% 0 0 0; */
}
.explain_ {
  font-size: 1.4rem;
  font-weight: 600;
}
.featureSpace {
  margin: 5% 0 20% 0;
}
.underLine {
  border-bottom: solid 4px rgb(128, 128, 128);
  padding: 0% 0 1% 0;
}
</style>
